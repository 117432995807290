

.titanbot-page{
  height: 100%;
  display: flex;
  flex-direction: column;
}

.chatbox{
  flex-grow: 1;
  display: flex;
  overflow-y: auto;
}

.chatbox-sidebar{
  background-color: rgb(241, 238, 235);
  width: 15%;
  overflow-y: auto;
}

.chatbox-messages{
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white !important;
}

.messages-list-scroll{
  flex-grow: 1;
  width: 100%;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  padding-top: 0.75em;
  /* align-items: center; */
}

.messages-list{
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 1em;

}

.user-input{
  margin: 1em;
  width: 70%;

}

.input-container{
  width: 100%;
  position: relative;
}

.chat-input-textarea{
  width: 100%;
  background-color: rgb(241, 240, 238);
  border-radius: 5px;
  border: none;
  outline: none;
  box-shadow: 0 0 8px 0 rgb(0,0,0,0.5);
  padding: 12px;
  font-size: 1.25em;
  padding-right: 40px;
}

.chat-message{
  display: flex;
  flex-direction: column;
}

.bot-message{
  background-color: rgb(247, 231, 215) !important ;
  border-radius: 10px;
  padding: 0.5em;
}

.avatar-message{
  display: flex;
  gap: 0.5em;
}

.avatar{
  border-radius: 50%;
  width: 2em;
  height: 2em;
 
}

.profile-icons{
  height: 100%;
  width: 100%;
}

.send-icon{
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  height: 2em;
  width: 2em;
  color: rgb(194, 191, 191);

}

.send-icon:hover{
  cursor: pointer;
}

.send-icon-black {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  height: 2em;
  width: 2em;
  color: black; 
}

.message{
    padding-left: 1em;
    width: 95%;
} 

.graph-image{
  margin-top: 0.5em;
  margin-left: auto;
  margin-right: auto;
}

.loading-dots{
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
  height: 1em;
  width: 1em;
}

.chat-history-list{
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  margin-bottom: 1em;
}

.history-list-button{
  height: 2em;
  width: 95%;
  background-color: rgb(228, 221, 212);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.history-list-button:hover{
  background-color: rgb(238, 192, 131);
  cursor: pointer;
}

.delete-chat-button{
  background-color:rgb(223, 212, 199);
  margin-right: 5px;
}

.delete-chat-button:hover{
  background-color:rgb(253, 253, 253);
}

.current-session{
  background-color: rgb(245, 210, 165);
}

.new-chat-button{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
  font-size: 1.15em;
  margin-bottom: 1em;
  background-color: rgb(206, 201, 196);
  border-radius: 5px;
}

.new-chat-icon{
  height: 1.5em;
  width: 1.5em;
}